var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "in-progress-modal",
        "header-bg-variant": "light",
        "header-text-variant": "dark",
        size: "sm",
        visible: _vm.show,
        "hide-header": "",
        "hide-footer": "",
        "no-close-on-backdrop": "",
        "content-class": "progress-style",
        "hide-backdrop": "",
      },
    },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c("b-spinner", { attrs: { small: "", type: "grow" } }),
              _vm._v(" " + _vm._s(_vm.message) + " "),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isStopable
        ? _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-center" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { size: "sm", variant: "danger" },
                        on: { click: _vm.stop },
                      },
                      [_vm._v(_vm._s(_vm.$t("button.stop")))]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }